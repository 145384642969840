import {useServerPage} from "../useServerPage.js";
import {getFromServer} from "../getFromServer.js";

export function useContentRoute(path) {
    const data = useState('data', () => ({}));
    const error = useState('error', () => {});

    async function fetchPageData(route, params) {
        const code = route.params.code;
        let url = code ? `${path}/${code}` : path;

        if (params && Object.values(params).length > 0) {
            url += '?' + new URLSearchParams(params);
        }

        try {
            data.value = await getFromServer(url);
            useBreadcrumbStore().items = data.value.breadcrumbs;
            gtm.openPage(path);
        } catch (e) {
            console.log(e);
            if (e.statusCode === 404) {
                error.value = e;
            }
        }
    }

    return {
        data,
        error,
        fetchPageData,
    };
}
